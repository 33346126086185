@import "./style-config.scss";
@import "./style-functions.scss";

:export {
  mainColor: $color-highlight;
  mainColorLight: $color-highlight-light;
  colorSecondary: $color-secondary;
  colorBase: $color-base;

  bagSvgCrossColor: $bag-svg-cross-color;
  bagSvgEllipseColor: $bag-svg-ellipse-color;
}


/* Tooltip component */
.tooltip {
  display: inline-block;

  .tooltiptext {
    visibility: hidden;
    position: absolute;
    padding: 10px;
    border-radius: 6px;
    background-color: $color-base;
    color: #fff;
    text-align: center;
    margin: 0 5px;
    z-index: 10;
    @include opacity(0);
    @include transition(visibility 0.2s ease-in-out, opacity 0.2s ease-in-out);

    @include lg {
      display: none;
    }
  }

  &:hover .tooltiptext {
    visibility: visible;
    @include opacity(1);
  }
}

/* Checkbox component */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  @include font-size(16px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    line-height: 25px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $color-secondary;
    border: solid 1px $color-secondary;
    border-radius: 3px;
    @include transition(all ease-in-out 0.2s);
  }
  &:hover input ~ .checkmark {
    background-color: $color-highlight-light;
    border: solid 1px $color-highlight;
  }
  input:checked ~ .checkmark {
    background-color: $color-highlight;
    border: solid 1px $color-highlight;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

/* Radio-Button component */
.radiobutton-container-not-available {
  opacity:0.5;
} 
.radiobutton-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  @include font-size(16px);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    line-height: 25px;
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $color-secondary;
    border: solid 1px $color-secondary;
    border-radius: 50%;
    @include transition(all ease-in-out 0.2s);
  }
  &:hover input ~ .checkmark {
    background-color: $color-highlight-light;
    border: solid 1px $color-highlight;
    cursor:pointer;
  }

  &:hover input:disabled ~ .checkmark {
    cursor: not-allowed;
  }
  input:checked ~ .checkmark {
    background-color: $color-highlight;
    border: solid 1px $color-highlight;
  }
  input:disabled ~ .checkmark {
    background-color: $color-base;
    border: solid 1px $color-base;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    top: 7px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
}
