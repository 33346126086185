/* Mixin functions */
/* Font size */
/* @include font-size(14px); */
@use 'sass:math';
@function calculateRem($size) {
    $remSize: math.div($size, 16px);
    @return $remSize * 1rem;
  }
  @mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
  }

  /* Media queries for Desktop-First */
  /* @include sm { } */
  // Small smartphones (portrait view)
  $screen-xs-max: 400px;
  // Small tablets and large smartphones (landscape view)
  $screen-sm-max: 576px;
  // Small tablets (portrait view)
  $screen-md-max: 768px;
  // Tablets and small desktops
  $screen-lg-max: 1024px;
  // Large tablets and desktops
  $screen-xl-max: 1200px;

  @mixin xs { @media only screen and (max-width: #{$screen-xs-max}) { @content; }}
  @mixin sm { @media only screen and (max-width: #{$screen-sm-max}) { @content; }}
  @mixin md { @media only screen and (max-width: #{$screen-md-max}) { @content; }}
  @mixin lg { @media only screen and (max-width: #{$screen-lg-max}) { @content; }}
  @mixin xl { @media only screen and (max-width: #{$screen-xl-max}) { @content; }}
  @mixin custom($screen) { @media only screen and (max-width: $screen+'px') { @content; }}

  /* Media queries for Mobile-First */
  @mixin mf-xs { @media only screen and (min-width: #{$screen-xs-max}) { @content; }}
  @mixin mf-sm { @media only screen and (min-width: #{$screen-sm-max}) { @content; }}
  @mixin mf-md { @media only screen and (min-width: #{$screen-md-max}) { @content; }}
  @mixin mf-lg { @media only screen and (min-width: #{$screen-lg-max}) { @content; }}
  @mixin mf-xl { @media only screen and (min-width: #{$screen-xl-max}) { @content; }}
  @mixin mf-custom($screen) { @media only screen and (min-width: $screen+'px') { @content; }}

  /* Transitions */
  /* @include transition(all ease-in-out 0.2s); */
  @mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -o-transition: $args;
    transition: $args;
  }

  /* Keyframes */
  /*
  @include keyframes(slide-down) {
    0% { opacity: 1; }
    90% { opacity: 0; }
  }
  @include animation('slide-down 5s 3');
  */
  @mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} { @content; }
    @-moz-keyframes #{$animation-name} { @content; }
    @-ms-keyframes #{$animation-name} { @content; }
    @-o-keyframes #{$animation-name} { @content; }
    @keyframes #{$animation-name} { @content; }
  }
  @mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
  }

  /* Opacity */
  /* @include opacity(0.8); */
  @mixin opacity($opacity) {
    opacity: $opacity;
    $opacity-ie: $opacity * 100;
    filter: alpha(opacity=$opacity-ie); //IE8
  }

  /* Box-sizing */
  /* @include box-sizing(border-box); */
  @mixin box-sizing($box-model) {
    -webkit-box-sizing: $box-model; // Safari <= 5
    -moz-box-sizing: $box-model; // Firefox <= 19
    box-sizing: $box-model;
  }
