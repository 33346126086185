@import "../../../styles/style-config.scss";
@import "../../../styles/style-functions.scss";

/* Menu on mobile */
.nav-menu-mobile-open {
    left: 0;
    z-index: 100;
    @include opacity(1);
  }
  .nav-menu-mobile-close {
    left: -1500px;
    z-index: -100;
    @include opacity(0);
  }
  .nav-menu-mobile {
    position: fixed;
    background-color: $menu-mobile-background-color;
    width: 100%;
    height: 100%;
    top: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 20px 0;
    text-align: center;
    @include transition(all ease-in-out 0.2s);
  
    @include mf-xl {
      display: none;
    }

    .close {
      position: absolute;
      right: 25px;
      cursor: pointer;

      @include sm {
        right: 15px;
        width: 15px;
      }
    }

    .actions {
      display: flex;
      justify-content: center;
      margin: 0 30px;
      padding: 20px;

      img {
        max-width: 250px;
      }
    }
  
    ul {
      width: 100%;
      line-height: 2.5;
  
      li {
        position: relative;
        list-style: none;
        font-weight: 500;
        @include font-size(20px);
        margin: 5px 0;
        text-align: center;
        color: $menu-mobile-text-color;
        background-color: $menu-mobile-text-background-color;
        cursor: pointer;
  
        &:hover {
          background-color: $menu-mobile-text-background-color-hover;
        }
  
        &:hover ul {
          height: auto;
          @include opacity(1);
        }
  
        ul {
          height: 0;
          line-height: 2;
          overflow: hidden;
          @include opacity(0);
          @include transition(opacity ease-in-out 0.2s);
  
          li {
            font-weight: 300;
            margin: 2px 0;
            background-color: $menu-mobile-text-background-color-hover;
  
            &:hover {
              background-color: $menu-mobile-text-background-color-hover;
            }
  
            &:last-child {
              padding-bottom: 20px;
            }
          }
          li.submenu-title {
            margin-top: 0;
          }
        }
      }
    }
  }
  